.input {
	width: 100%;
	display: flex;	
}

.search {
	width: 100%;
	font-family: 'Raleway', sans-serif;	
	font-weight: 200;
}

.search label,
.search input {
	font-family: 'Raleway', sans-serif;	
	font-weight: 400;
}

.search-btn {
	position: relative;
	bottom: 3px;
	color: black !important;
	justify-self: end;		
}

