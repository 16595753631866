.signs {
	margin: 5vh 8px 5vh 32px;
}

.subtitle {
	font-family: 'Raleway', sans-serif;
}

.singleSign {
	display: flex;
	justify-content: space-between;	
	margin: 5vh 0;
}

