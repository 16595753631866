.header {
	display: flex;
	align-items: center;	
	flex-direction: column;	
	width: 100%;
	margin: 5vh 0;
}

.title {
	font-size: 3rem;
	text-transform: uppercase;
	font-weight: 400;
}

@media (max-width: 900px) {
	.header {
		justify-content: space-evenly;
		height: 25vh;
	}
}
